import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "../pages/Contact";
import Faqs from "../pages/Faqs";
import NotFound from "../pages/NotFound";
import HomeTwo from "../pages/home-two/HomeTwo";
import ScrollToTop from "../components/common/ScrollToTop";

const Routers = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomeTwo />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/faq" element={<Faqs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routers;
