import React from 'react';
import { useContract, useContractMetadata, useContractRead, useNFT, ThirdwebNftMedia } from "@thirdweb-dev/react";
import { LOTTERY_CONTRACT_ADDRESS } from "../constants/addresses";

const PrizeNFT = () => {
    const { contract: lotteryContract } = useContract(LOTTERY_CONTRACT_ADDRESS);
    const { data: nftContractAddress } = useContractRead(lotteryContract, "nftContract");
    const { data: nftTokenId } = useContractRead(lotteryContract, "tokenId");
    const { contract: nftContract } = useContract(nftContractAddress);
    const { data: nftContractMetadata, isLoading: nftContractMetadataLoading } = useContractMetadata(nftContract);
    const { data: nft, isLoading: nftLoading } = useNFT(nftContract, nftTokenId);

    return (
        <div>
            {(!nftContractMetadataLoading && nftContractMetadata && !nftLoading && nft) ? (
                <div>
                    <div>
                        <p style={{ textAlign: "center" }}>{nft?.metadata.name}</p>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center", // Centrar horizontalmente
                        alignItems: "center",    // Centrar verticalmente
                        height: "100%",          // Ajustar a la altura del contenedor padre
                        width: "100%",           // Ajustar al ancho del contenedor padre
                    }}>
                        <ThirdwebNftMedia
                            metadata={nft?.metadata}
                            height="80%"
                            width="80%"
                            style={{ borderRadius: '10px' }}
                        />
                    </div>
                </div>
            ) : (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <svg
                        style={{ margin: 'auto', background: 'none', display: 'block', shapeRendering: 'auto' }}
                        width="50px"
                        height="50px"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                    >
                        <circle cx="50" cy="50" fill="none" stroke="#fff" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
                            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                        </circle>
                    </svg>
                </div>
            )}
        </div>
    );
};

export default PrizeNFT;
