import React from "react";
import { Link } from "react-router-dom";

const TrendingCategory = () => {
  return (
    <section className="nft2-categories">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-8">
            <div className="title-style-2">
              <h2>
                Recently Listed in Evil Kongs
              </h2>
            </div>
          </div>
        </div>
        <div className="nft2-categories-list">
          <div className="row g-4 justify-content-center">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="nft-hc-item">
                <div className="thumb-wrapper">
                  <Link onClick={() => window.open("https://goblinsaga.xyz", "_blank")}>
                    <img
                      src="assets/img/home2/goblin-site.png"
                      className="img-fluid"
                      alt="item-thumb"
                    />
                  </Link>
                </div>
                <div className="hc-item-title">
                  <h6>Goblin Saga</h6>
                </div>
                <div className="hc-item-bid-amount d-flex align-items-center justify-content-between">
                  <div>
                    <span>Mint Price</span>
                    <h6>9.0 POL</h6>
                  </div>
                </div>
                <div className="spacer"></div>
                <div className="hc-item-author-info d-flex align-items-center">
                  <img
                    src="assets/img/home2/website.png"
                    className="img-fluid rounded-circle"
                    alt="author"
                  />
                  <div className="author-title">
                    <a onClick={() => window.open("https://goblinsaga.xyz", "_blank")} style={{ cursor: "pointer" }}>
                      <h6>Project Site</h6>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="nft-hc-item">
                <div className="thumb-wrapper">
                  <Link onClick={() => window.open("https://epopgirls.xyz", "_blank")}>
                    <img
                      src="assets/img/home2/epop-site.png"
                      className="img-fluid"
                      alt="item-thumb"
                    />
                  </Link>
                </div>
                <div className="hc-item-title">
                  <h6>Epop Girls</h6>
                </div>
                <div className="hc-item-bid-amount d-flex align-items-center justify-content-between">
                  <div>
                    <span>Mint Price</span>
                    <h6>5.0 POL</h6>
                  </div>
                </div>
                <div className="spacer"></div>
                <div className="hc-item-author-info d-flex align-items-center">
                  <img
                    src="assets/img/home2/website.png"
                    className="img-fluid rounded-circle"
                    alt="author"
                  />
                  <div className="author-title">
                    <h6 onClick={() => window.open("https://epopgirls.xyz", "_blank")} style={{ cursor: "pointer" }}>Project Site</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="nft-hc-item">
                <div className="thumb-wrapper">
                  <Link>
                    <img
                      src="assets/img/home2/spot.png"
                      className="img-fluid"
                      alt="item-thumb"
                    />
                  </Link>
                </div>
                <div className="hc-item-title">
                  <h6>Available Spot</h6>
                </div>
                <div className="hc-item-bid-amount d-flex align-items-center justify-content-between">
                  <div>
                    <span>Mint Price</span>
                    <h6>TBA</h6>
                  </div>
                </div>
                <div className="spacer"></div>
                <div className="hc-item-author-info d-flex align-items-center">
                  <img
                    src="assets/img/home2/website.png"
                    className="img-fluid rounded-circle"
                    alt="author"
                  />
                  <div className="author-title">
                    <h6>Project Site</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="nft-hc-item">
                <div className="thumb-wrapper">
                  <Link>
                    <img
                      src="assets/img/home2/spot.png"
                      className="img-fluid"
                      alt="item-thumb"
                    />
                  </Link>
                </div>
                <div className="hc-item-title">
                  <h6>Available Spot</h6>
                </div>
                <div className="hc-item-bid-amount d-flex align-items-center justify-content-between">
                  <div>
                    <span>Mint Price</span>
                    <h6>TBA</h6>
                  </div>
                </div>
                <div className="spacer"></div>
                <div className="hc-item-author-info d-flex align-items-center">
                  <img
                    src="assets/img/home2/website.png"
                    className="img-fluid rounded-circle"
                    alt="author"
                  />
                  <div className="author-title">
                    <h6>Project Site</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrendingCategory;

export function Clock() {
  return (
    <span>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.9998 2.40625C4.63556 2.40625 1.89844 5.1434 1.89844 8.5076C1.89844 11.872 4.63559 14.6091 7.9998 14.6091C11.3642 14.6091 14.1012 11.872 14.1012 8.5076C14.1012 5.1434 11.364 2.40625 7.9998 2.40625ZM7.9998 13.5034C5.24533 13.5034 3.0044 11.2623 3.0044 8.50782C3.0044 5.75336 5.2453 3.51243 7.9998 3.51243C10.7543 3.51243 12.9952 5.75336 12.9952 8.50782C12.9952 11.2624 10.7543 13.5034 7.9998 13.5034Z"
          fill="black"
        />
        <path
          d="M9.69585 8.32405H8.22123V6.18581C8.22123 5.88037 7.97367 5.63281 7.66823 5.63281C7.3628 5.63281 7.11523 5.88037 7.11523 6.18581V8.87705C7.11523 9.18249 7.3628 9.43005 7.66823 9.43005H9.69588C10.0013 9.43005 10.2489 9.18249 10.2489 8.87705C10.2488 8.57161 10.0013 8.32405 9.69585 8.32405Z"
          fill="black"
        />
        <path
          d="M4.86712 12.4623C4.64003 12.2579 4.29034 12.2765 4.08613 12.5034L2.42714 14.3467C2.2227 14.5736 2.24133 14.9233 2.46823 15.1275C2.57385 15.2229 2.7062 15.2697 2.83801 15.2697C2.98935 15.2697 3.14013 15.2079 3.24926 15.0865L4.90824 13.2431C5.11265 13.0162 5.09402 12.6665 4.86712 12.4623Z"
          fill="black"
        />
        <path
          d="M13.5721 14.3468L11.9131 12.5035C11.7092 12.2764 11.3594 12.2579 11.1323 12.4624C10.9052 12.6666 10.8869 13.0163 11.0912 13.2432L12.7502 15.0865C12.8591 15.2078 13.0099 15.2695 13.1612 15.2695C13.2929 15.2695 13.4252 15.2227 13.531 15.1276C13.7581 14.9233 13.7763 14.5737 13.5721 14.3468Z"
          fill="black"
        />
        <path
          d="M4.77386 1.53238C3.67505 0.456452 1.90384 0.458108 0.825691 1.53645C0.289256 2.07266 -0.00383705 2.78216 3.7937e-05 3.53422C0.00410042 4.27725 0.298662 4.97293 0.829722 5.49311C0.937378 5.59836 1.07691 5.6509 1.21663 5.6509C1.35819 5.6509 1.49975 5.59671 1.60759 5.48905L4.77808 2.31857C4.88242 2.21407 4.94067 2.07229 4.93992 1.92466C4.93921 1.77698 4.87946 1.63579 4.77386 1.53238ZM1.27728 4.25512C1.1665 4.0319 1.10731 3.7849 1.10603 3.52831C1.10363 3.07413 1.28169 2.64425 1.60759 2.31835C1.93275 1.99338 2.36262 1.83098 2.79487 1.83098C3.04759 1.83098 3.30124 1.88645 3.53496 1.99741L1.27728 4.25512Z"
          fill="black"
        />
        <path
          d="M15.174 1.53638C14.0957 0.458225 12.3244 0.456382 11.2257 1.53231C11.1202 1.63572 11.0603 1.77694 11.0596 1.92456C11.0588 2.07222 11.1173 2.21416 11.2216 2.31847L14.3921 5.48895C14.4999 5.59698 14.6415 5.65098 14.7831 5.65098C14.9228 5.65098 15.0625 5.59845 15.17 5.49283C15.7012 4.97283 15.9958 4.27718 15.9997 3.53412C16.0035 2.78228 15.7102 2.07278 15.174 1.53638ZM14.7224 4.25524L12.4648 1.99756C13.0985 1.69672 13.8769 1.80347 14.3921 2.31865C14.718 2.64456 14.8961 3.07421 14.8937 3.52862C14.8924 3.78502 14.8332 4.03221 14.7224 4.25524Z"
          fill="black"
        />
      </svg>
    </span>
  );
}
