import React from "react";
import { Link } from "react-router-dom";

const TrendingCollection = () => {
  return (
    <section className="nft2-collections">
      <div className="container">
        <div className="text-center">
          <div className="title-style-2 text-center d-flex align-items-center justify-content-center">
            <h2 className="mb-0">Evil Kongs Collections</h2>
          </div>
          <p>
            On Arbitrum Network
          </p>
        </div>
        <div className="row g-3">
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link onClick={() => window.open("https://launchpad.heymint.xyz/mint/evil-kongs", "_blank")} className="d-block">
                <img
                  src="assets/img/home2/ekongs-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/ekongs.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Evil Kongs</h6>
                  <span>
                    0.002 ETH
                  </span>
                </div>
                <img style={{ width: "35%" }} src="assets/img/heymint-logo.webp"/>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link onClick={() => window.open("https://launchpad.heymint.xyz/mint/ahegao-succubus", "_blank")} className="d-block">
                <img
                  src="assets/img/home2/ahegao-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/ahegao-logo.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Ahegao Succubus</h6>
                  <span>
                    0.002 ETH
                  </span>
                </div>
                <img style={{ width: "35%" }} src="assets/img/heymint-logo.webp"/>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link onClick={() => window.open("https://launchpad.heymint.xyz/mint/abyss-lords", "_blank")} className="d-block">
                <img
                  src="assets/img/home2/abyss-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover "
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/abyss-logo.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Abyss Lords</h6>
                  <span>
                    0.002 ETH
                  </span>
                </div>
                <img style={{ width: "35%" }} src="assets/img/heymint-logo.webp"/>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link onClick={() => window.open("https://launchpad.heymint.xyz/mint/evil-heroes", "_blank")} className="d-block">
                <img
                  src="assets/img/home2/ehero-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/eher-logo.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Evil Heroes</h6>
                  <span>
                    0.002 ETH
                  </span>
                </div>
                <img style={{ width: "35%" }} src="assets/img/heymint-logo.webp"/>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrendingCollection;
